
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap'
import '../App.css';

function Sticker() {

  return (
    <div className="App-header" style={{ paddingTop: '80px' }}>
 
      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='sticker'>
        <Card.Body>
          <Card.Title>
            <b>Sticker</b>{' '}
            <Badge pill bg="primary">
              1.1.0
            </Badge>
          </Card.Title>
          <Card.Text>
            快速后处理你的表情包，支持动图/单帧！
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '5px' }}>下载</div>
              <Button variant="primary" href={`https://dist-1304010062.cos.ap-nanjing.myqcloud.com/birth/FastToSticker.exe`}>Windows</Button>{' '}
              <Button variant="outline-danger" href={`https://dotnet.microsoft.com/zh-cn/download/dotnet/6.0/runtime`}>如无法运行请点击安装 .NET 6 环境（运行桌面应用, 下载 x64）</Button>{' '}
          </div>
        </Card.Body>
      </Card> 
    </div>
  );
}

export default Sticker;
